import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1647702958/AE678F0F-F35E-4F16-A41F-E371F1055E56_ju76tw.jpg",
        "alt": "Ilustración película Anette por bethzait Becerra"
      }}></img></p>
    <p>{`Aunque parezca tarde para hacer un recuento de mis películas favoritas del 2021, la temporada de premios siempre es un buen pretexto para retomar la conversación. Además, este es el primer número de Colibrí en el 2022 y muchas de las cintas se estrenaron los últimos meses del año pasado. De hecho, llegaron a plataformas de `}<em parentName="p">{`streaming`}</em>{` hace pocas semanas.`}</p>
    <p>{`Por cierto, antes de comenzar, me parece importante aclarar que no hay orden en esta lista. No hay un primer o tercer lugar, porque todas me gustaron más o menos lo mismo y lo que realmente pretendo con la lista es que puedan interesarles. Dicho lo anterior comencemos.`}</p>
    <p><strong parentName="p">{`Una de las películas que más esperé fue Annette (2021). Un musical dirigido por el siempre atrevido Léos Carax, con un cast protagonista tan conocido como talentoso: Marion Cotillard, Adam Driver y Simon Helberg.`}</strong></p>
    <p>{`La premisa es simple: un comediante ácido del `}<em parentName="p">{`stand up`}</em>{` y una respetada cantante de ópera viven su romance y el nacimiento de su hija rodeados de su apabullante fama y el ojo constante de las cámaras. `}<strong parentName="p">{`Y aunque, de entrada, luce como una historia de amor, se convierte en una lectura cruda de las relaciones, y sobre todo de la crianza, en el mundo del espectáculo.`}</strong></p>
    <p>{`Verla no es sencillo, en especial si no te gustan los musicales, pero tampoco es fácil si eres un aficionado de esta forma de cine. El director francés es conocido por evitar las narraciones convencionales y después de Holy Motors (2012), repite el gusto por la experimentación en su forma de aproximarse a la idea del musical.`}</p>
    <p>{`Si bien la película abre con un número que llena de energía la pantalla, conforme avanza, las canciones se dirigen a un extremo contrario, con letras muy enfáticas, repetitivas, y sonidos oscuros llenos de drama, magia y tragedia. En definitiva, los hermanos Sparks, quienes musicalizaron la historia, no tenían la intención de hacer un `}<em parentName="p">{`soundtrack`}</em>{` ameno, al estilo La La Land, para que lo bailes y lo cantes mientras lo reproduces en tu teléfono. Sin embargo, es una banda sonora cautivante, que al combinarse con los momentos de fantasía y sátira que ofrece la historia, hace de Annette una película que se queda contigo semanas después de verla.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1647702958/635C3BB0-F4EF-4DAC-9671-D5ED00F3326F_vnahi0.jpg",
        "alt": "Ilustración película Titane por bethzait Becerra "
      }}></img></p>
    <p>{`Si te llamó la atención todo lo que he dicho sobre ella, la puedes ver en la plataforma de `}<em parentName="p">{`streaming`}</em>{` MUBI. Y ya que andes por ahí, aprovechando tus 7 días de prueba gratis, ve la siguiente película de mi lista: `}<strong parentName="p">{`Titane (2021), de la directora francesa Julia Ducournau, que tal vez ubiques por su ópera prima Raw o Voraz, sobre una joven que después de su novatada en la universidad comienza a desarrollar una necesidad caníbal.`}</strong></p>
    <p>{`Como puedes notar, los temas que interesan a la directora se desarrollan a través del suspenso y el horror, pero en Titane agrega un factor extra, fundamental para comprender la historia: el cuerpo. A grandes rasgos, esta película se trata de una chica que, para huir de ciertas circunstancias que la acechan, se hace pasar por el hijo muerto de un bombero. Este cometido se logra a través de la transformación de su cuerpo, pero también aprovecha el luto de un padre que ve sólo lo que quiere ver.`}</p>
    <p>{`En la relación que ambos construyen se van sanando ciertos traumas del pasado. En el lado más evidente, el bombero Vincent supera el dolor que implicó perder a su hijo, por el otro lado Alexia, la protagonista, encuentra la aceptación que siempre buscó de su familia, mientras descubre su nueva capacidad para sentir apego por otras personas.`}</p>
    <p>{`Ahora, debo advertir que en el camino pasan muchas cosas “extrañas”. La primera es que Alexia, no es un personaje con el que te puedas identificar fácilmente. No es una víctima de las circunstancias, por el contrario, es una victimaria cínica que no busca ser querida por la audiencia.`}</p>
    <p>{`La segunda es la razón que da nombre a la película. Durante su infancia, Alexia sufre un accidente automovilístico y al atenderla, los doctores colocan una placa de titanio en su cabeza. Parece que a partir de aquí (aunque pienso que es mucho antes), Alexia desarrolla una especie de atracción sexual por los autos que además es correspondida.`}</p>
    <p>{`Este es el elemento más ciencia ficción de la trama y lo menciono hasta el final porque suele ser el gancho que más llama la atención, sin embargo, esta particularidad de la protagonista es contextual para su desarrollo, es decir: más que ser el centro de la trama, es una herramienta para contar como una persona restablece los vínculos con su humanidad a través de la transformación de su cuerpo.`}</p>
    <p>{`Bueno, para no extendernos más, pasemos con la tercera y última película: `}<strong parentName="p">{`El poder del perro (2021)`}</strong>{`. Esta cinta se puede ver en Netflix, `}<strong parentName="p">{`está dirigida por la neozelandesa Jane Campion y es protagonizada por Benedict Cumberbatch, Kristen Dunst y Kodi Smit-McPhee.`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1647702958/71397D44-BFC1-46B2-925F-37DB21D8AC80_wvpmln.jpg",
        "alt": "Ilustración película El poder del perro por bethzait becerra"
      }}></img></p>
    <p>{`Situada en el viejo oeste, la película cuenta la historia de un par de hermanos, dueños de un rancho, que son muy diferentes entre sí. Un día George, el más amable, se casa con Rose una mujer viuda y junto a su hijo se mudan a la gran casa. Ahí comienza un ciclo de terror para el personaje Kristen Dunst, pues es acosada por la crueldad y el desdén de Phil, el hermano cruel, quien también molesta a su hijo.`}</p>
    <p><strong parentName="p">{`Benedict Cumberbatch ofrece una gran interpretación, pues logra convencerte de odiarlo profundamente para después compadecerte. Por otro lado, la historia se acompaña de paisajes majestuosos del Montana de 1925 que juegan un papel fundamental para mostrar los sentimientos de soledad y conexión entre los personajes.`}</strong>{` Por último, la directora teje de manera silenciosa la evolución en las dinámicas de poder a lo largo de la trama. Ninguno de los personajes comienza donde empezó, pero su transformación es un camino claro, aunque de huellas muy sutiles.`}</p>
    <p>{`Listo. Esas fueron mis tres películas favoritas del 2021. Algunas llegarán a la temporada de premios, así que no se las pierdan. Adicionalmente, no me quiero despedir sin mencionar otras cintas: La hija oscura, Licorice Pizza, La tragedia de Macbeth, La peor persona del mundo, Noche de Fuego, C ́mon C ́mon y Benedetta.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      